import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import { PageHead } from 'components/nav/head';
import { ProjectDashboard } from 'components/project/tabs/dashboard';
import { useProject } from 'core/api/fetch/project';

const Project: NextPage = () => {
  const router = useRouter();
  const handle = router.query.handle as string;
  const { data } = useProject(handle);

  return (
    <div>
      <PageHead pageTitle={data?.title ?? handle} />
      {data?.id && <ProjectDashboard projectId={data.id} handle={handle} />}
    </div>
  );
};

export default Project;
