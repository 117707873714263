import { Box, Typography, useTheme } from '@mui/material';

import { DEFAULT_SPACING } from 'styles/material';

interface Props {
  bumpDown?: boolean;
  title: string | undefined;
}

export const SectionTitle = ({ bumpDown, title }: Props) => {
  const theme = useTheme();
  return (
    <div>
      <Typography variant="h6" sx={{ mt: bumpDown ? DEFAULT_SPACING : 0 }}>
        {title}
      </Typography>
      <Box
        sx={{
          background: theme.palette.primary.main,
          borderRadius: 10,
          height: 6,
          mb: 3,
          mt: 0.5,
          width: 80,
        }}
      ></Box>
    </div>
  );
};
