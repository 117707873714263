import useSWR from 'swr';

import { ENDPOINTS, getProjectStatsFetcher } from 'core/api/endpoints';

export const useProjectStats = (projectId: string) => {
  const { data, error } = useSWR(
    [projectId, ENDPOINTS.GET_PROJECT_STATS],
    getProjectStatsFetcher,
  );

  return {
    data,
    error,
    loading: !error && !data,
  };
};
