import { Paper, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

import { SectionTitle } from 'components/core/section/title';
import { ProjectInfo } from 'components/project/info';
import { useProject } from 'core/api/fetch/project';
import { useProjectStats } from 'core/api/fetch/project-stats';
import { SecondaryStats } from 'core/api/types';
import { ProjectProps } from 'core/dom/types';

type Columns = keyof SecondaryStats | 'name';

type ColumnNames = Record<Columns, string>;

const ProjectColumnNames: ColumnNames = {
  floorPrice: 'Floor price (all collections)',
  name: 'Project title',
  numSales: 'Number of sales (all time)',
  totalListed: 'Current number listed',
  totalSalesVolume: 'Total sales volume (SOL)',
};

const CollectionColumnNames: ColumnNames = {
  floorPrice: 'Current floor price',
  name: 'Collection title',
  numSales: 'Number of sales (all time)',
  totalListed: 'Current number listed',
  totalSalesVolume: 'Total sales volume (SOL)',
};

const DefaultState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'floorPrice', sort: 'desc' }],
  },
};

const generateColumns = (names: ColumnNames): GridColDef[] =>
  Object.keys(names)
    .sort((a, b) => {
      if (a === 'name') {
        return -1;
      }
      return a < b ? -1 : 1;
    })
    .map(c => ({
      field: c,
      headerName: names[c as Columns],
      width: 300,
    }));

export function ProjectDashboard({ handle, projectId }: ProjectProps) {
  const { data: stats, error } = useProjectStats(projectId);
  const { data: project, error: projectError } = useProject(handle);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  if (!stats || error || !project || projectError) {
    return <></>;
  }

  return (
    <div>
      {!desktop && <ProjectInfo handle={handle} />}
      <SectionTitle title="Dashboard" />
      <div className="w-full">
        <Paper>
          <DataGrid
            autoHeight
            hideFooter
            disableRowSelectionOnClick
            rows={[
              {
                ...(stats.projectStats ?? {}),
                id: handle,
                name: project.title ?? handle,
              },
            ]}
            columns={generateColumns(ProjectColumnNames)}
            density="compact"
            initialState={DefaultState}
            components={{ Toolbar: GridToolbar }}
          />
        </Paper>
      </div>
      <div className="w-full mt-6">
        <Paper>
          <DataGrid
            autoHeight
            disableRowSelectionOnClick
            rows={stats.collections.map((c, idx) => ({
              ...(c.collectionStats ?? {}),
              id: idx,
              name: c.collectionTitle,
            }))}
            columns={generateColumns(CollectionColumnNames)}
            getRowClassName={params =>
              params.row.id % 2 === 0 ? '' : 'dark-row'
            }
            density="compact"
            initialState={DefaultState}
            components={{ Toolbar: GridToolbar }}
          />
        </Paper>
      </div>
    </div>
  );
}
